import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src477529347/src/autopatcher-docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "firewall-configuration"
    }}>{`Firewall configuration`}</h1>
    <p>{`In order for the SSM agent and optionally the AutoPatcher Installer tool
to be able to work properly on the target machines, outbound connections
to the HTTPS endpoints listed in the following section on port 443 should be allowed by the firewall.
If those connections can not be made directly due to security restrictions applied on the machine
the appropriate proxy settings should be applied: `}<a parentName="p" {...{
        "href": "#proxy-settings"
      }}>{`read more`}</a></p>
    <h2 {...{
      "id": "list-of-endpoints-ssm-agent-uses"
    }}>{`List of endpoints SSM agent uses`}</h2>
    <h3 {...{
      "id": "ssm-endpoints-used-by-ssm-agents"
    }}>{`SSM endpoints used by SSM agents`}</h3>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`ssm.<region>.amazonaws.com`}</inlineCode></li>
    </ul>
    <p>{`Related AWS documentation: `}<a parentName="p" {...{
        "href": "https://docs.aws.amazon.com/general/latest/gr/rande.html#ssm_region"
      }}>{`https://docs.aws.amazon.com/general/latest/gr/rande.html#ssm_region`}</a></p>
    <h3 {...{
      "id": "s3-endpoints"
    }}>{`S3 endpoints`}</h3>
    <p>{`SSM agent uses S3 to store logs generated by running the update `}<strong parentName="p">{`and`}</strong>{` to download some packages
and artifacts needed to perform the patching. Therefore the following addresses should be white-listed.`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`s3.amazonaws.com`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`s3.<region>.amazonaws.com`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`s3-<region>.amazonaws.com`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`s3.dualstack.<region>.amazonaws.com`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`*.s3.amazonaws.com`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`*.s3.<region>.amazonaws.com`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`*.s3-<region>.amazonaws.com`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`*.s3.dualstack.<region>.amazonaws.com`}</inlineCode></li>
    </ul>
    <p>{`Related AWS documentation: `}<a parentName="p" {...{
        "href": "https://docs.aws.amazon.com/general/latest/gr/rande.html#s3_region"
      }}>{`https://docs.aws.amazon.com/general/latest/gr/rande.html#s3_region`}</a></p>
    <h3 {...{
      "id": "other-endpoints-used-by-ssm-agents"
    }}>{`Other endpoints used by SSM agents`}</h3>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`ssm.<region>.amazonaws.com`}</inlineCode>{`: The endpoint for the Systems Manager service.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`ec2messages.<region>.amazonaws.com`}</inlineCode>{`: Systems Manager uses this endpoint to make calls from SSM Agent to the Systems Manager service.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`ec2.<region>.amazonaws.com`}</inlineCode>{`: If you're using VSS-enabled snapshots.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`ssmmessages.<region>.amazonaws.com`}</inlineCode>{`: If you're connecting to instances through Session Manager.`}</li>
    </ul>
    <hr></hr>
    <p><strong parentName="p">{`NOTE`}</strong></p>
    <p>{`AutoPatcher is currently deployed in the `}<inlineCode parentName="p">{`eu-central-1`}</inlineCode>{` region (`}<em parentName="p">{`Frankfurt`}</em>{`). However if a machine is registered in the region other than `}<inlineCode parentName="p">{`eu-central-1`}</inlineCode>{` (e.g. `}<inlineCode parentName="p">{`eu-west-1`}</inlineCode>{`) it needs to have the corresponding endpoints whitelisted as well.`}</p>
    <hr></hr>
    <h2 {...{
      "id": "autopatcher-installer-api-url"
    }}>{`AutoPatcher Installer API URL`}</h2>
    <p>{`If you're using the AutoPatcher Installer tool the target machine's firewall should be able to make calls to `}<inlineCode parentName="p">{`https://vv78e7dv4f.execute-api.eu-central-1.amazonaws.com/prod`}</inlineCode>{` URL. This is an API used to register external machines in AutoPatcher.`}</p>
    <h2 {...{
      "id": "proxy-settings"
    }}>{`Proxy settings`}</h2>
    <p>{`The instructions in the following subsections use the `}<inlineCode parentName="p">{`<proxy_address>`}</inlineCode>{` placeholder in different places. Be careful and replace it in the provided scripts with the appropriate value for your environment.`}</p>
    <h3 {...{
      "id": "proxy-settings-on-linux-machines"
    }}>{`Proxy settings on Linux machines`}</h3>
    <h4 {...{
      "id": "using-ap-installer-behind-proxy"
    }}>{`Using `}<inlineCode parentName="h4">{`ap-installer`}</inlineCode>{` behind proxy`}</h4>
    <p>{`This section is for non-AWS machines only.`}</p>
    <p>{`The script below shows an example of how to download and run the `}<inlineCode parentName="p">{`ap-installer`}</inlineCode>{` on a Linux machine behind a proxy:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`# Setting the appropriate environment variables
export http_proxy=<proxy_address>
export https_proxy=<proxy_address>

# Downloading the binary
wget https://auto-patcher-core-prod-integratorcliuploadbucket-x9ofi9eidydb.s3.amazonaws.com/ap-installer-linux -O ap-installer

# Making the binary executable
chmod +x ap-installer

# Running the tool to register the machine in AutoPatcher
./ap-installer INSTALLER_KEY
`}</code></pre>
    <p>{`More detailed information about `}<inlineCode parentName="p">{`ap-installer`}</inlineCode>{` is provided in this section: `}<a parentName="p" {...{
        "href": "/setting-up-ssm#automatic-installation"
      }}>{`Setting up SSM agent -> Automatic installation`}</a>{`.`}</p>
    <h4 {...{
      "id": "configuring-the-installed-ssm-agent-to-use-proxy"
    }}>{`Configuring the installed SSM Agent to use proxy`}</h4>
    <p>{`This section is for all machines, AWS or hybrid.`}</p>
    <p>{`All the details about setting up proxy for SSM Agent on various Linux machines are provided in the official AWS documentation: `}<a parentName="p" {...{
        "href": "https://docs.aws.amazon.com/systems-manager/latest/userguide/sysman-proxy-with-ssm-agent.html"
      }}>{`Configure SSM Agent to use a proxy (Linux)`}</a></p>
    <h3 {...{
      "id": "proxy-settings-on-windows-machines"
    }}>{`Proxy settings on Windows machines`}</h3>
    <h4 {...{
      "id": "using-ap-installerexe-behind-proxy"
    }}>{`Using `}<inlineCode parentName="h4">{`ap-installer.exe`}</inlineCode>{` behind proxy`}</h4>
    <p>{`This section is for non-AWS machines only.`}</p>
    <p>{`The following PowerShell script shows how to download and run the `}<inlineCode parentName="p">{`ap-installer.exe`}</inlineCode>{` executable on a Windows machine behind a proxy:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-powershell"
      }}>{`# Downloading the executable. Note the -Proxy flag.
Invoke-WebRequest -Uri https://auto-patcher-core-prod-integratorcliuploadbucket-x9ofi9eidydb.s3.amazonaws.com/ap-installer-windows.exe -Proxy <proxy_address> -OutFile ap-installer.exe

# Setting the appropriate environment variables
$env:http_proxy = '<proxy_address>'
$env:https_proxy = '<proxy_address>'

# Running the executable hence registering the machine in AutoPatcher.
.\\ap-installer.exe INSTALLER_KEY
`}</code></pre>
    <p>{`More detailed information about `}<inlineCode parentName="p">{`ap-installer`}</inlineCode>{` is provided in this section: `}<a parentName="p" {...{
        "href": "/setting-up-ssm#automatic-installation"
      }}>{`Setting up SSM agent -> Automatic installation`}</a>{`.`}</p>
    <h4 {...{
      "id": "proxy-setting-for-the-ssm-agent"
    }}>{`Proxy setting for the SSM agent`}</h4>
    <p>{`This section is for all machines, AWS or hybrid.`}</p>
    <p>{`The following PowerShell code snippet should be used for configuring egress Internet access for the SSM agent through proxy:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-powershell"
      }}>{`$serviceKey = "HKLM:\\SYSTEM\\CurrentControlSet\\Services\\AmazonSSMAgent"
$keyInfo = (Get-Item -Path $serviceKey).GetValue("Environment")
$proxyVariables = @("http_proxy=<proxy_address>", "https_proxy=<proxy_address>", "no_proxy=169.254.169.254")

If($keyInfo -eq $null) {
    New-ItemProperty -Path $serviceKey -Name Environment -Value $proxyVariables -PropertyType MultiString -Force
} else {
    Set-ItemProperty -Path $serviceKey -Name Environment -Value $proxyVariables
}
Restart-Service AmazonSSMAgent
`}</code></pre>
    <p>{`More information can be found under in the official AWS documentation: `}<a parentName="p" {...{
        "href": "https://docs.aws.amazon.com/systems-manager/latest/userguide/sysman-install-ssm-proxy.html"
      }}>{`Configure SSM Agent to use a proxy for Windows Server instances`}</a>{`.`}</p>
    <h4 {...{
      "id": "proxy-setting-for-the-windows-update-agent"
    }}>{`Proxy setting for the Windows Update Agent`}</h4>
    <p>{`To setup a proxy for Windows Update Agent run the following PowerShell script:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-powershell"
      }}>{`netsh winhttp set proxy proxy-server="http=<proxy_address>;https=<proxy_address>" bypass-list=169.254.169.254
`}</code></pre>
    <p>{`If the Windows Update Agent still has issues after this, try the following command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-powershell"
      }}>{`bitsadmin.exe /Util /SetIEProxy LocalSystem Manual_proxy <proxy_address> ";"
`}</code></pre>
    <h2 {...{
      "id": "required-non-blocked-urls-to-work-with-the-ui"
    }}>{`Required non-blocked URLs to work with the UI`}</h2>
    <p>{`Some local firewall configuration may prevent accessing below links. Those links
are required to work with AutoPatcher UI.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://widget.ybug.io"
        }}>{`https://widget.ybug.io`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.googletagmanager.com"
        }}>{`https://www.googletagmanager.com`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.datadoghq-browser-agent.com"
        }}>{`https://www.datadoghq-browser-agent.com`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://cdn.mouseflow.com"
        }}>{`https://cdn.mouseflow.com`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://cdnjs.cloudflare.com"
        }}>{`https://cdnjs.cloudflare.com`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://fonts.gstatic.com"
        }}>{`https://fonts.gstatic.com`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://fonts.googleapis.com"
        }}>{`https://fonts.googleapis.com`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://ybug.io"
        }}>{`https://ybug.io`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://lh3.googleusercontent.com"
        }}>{`https://lh3.googleusercontent.com`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://api.rollbar.com"
        }}>{`https://api.rollbar.com`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://api.autopatcher.nordcloudapp.com"
        }}>{`https://api.autopatcher.nordcloudapp.com`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.google-analytics.com"
        }}>{`https://www.google-analytics.com`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://rum-http-intake.logs.datadoghq.com"
        }}>{`https://rum-http-intake.logs.datadoghq.com`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://o2.mouseflow.com"
        }}>{`https://o2.mouseflow.com`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://ybug-uploads-prod.s3.eu-central-1.amazonaws.com"
        }}>{`https://ybug-uploads-prod.s3.eu-central-1.amazonaws.com`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://login.nordcloudapp.com"
        }}>{`https://login.nordcloudapp.com`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      